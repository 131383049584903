import type { AlpineComponent } from 'alpinejs'
import Swiper from 'swiper'
import { Pagination, Navigation, A11y } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import { merge } from 'ts-deepmerge'

// if better types get needed check
// https://github.com/alpinejs/alpine/issues/2199#issuecomment-1809892127

export function Slider(config: SwiperOptions): AlpineComponent<{
  swiper: Swiper | undefined
  init(): void
  discard(): void
  goToSlide(index: number): void
}> {
  const defaultConfig: SwiperOptions = {
    modules: [Pagination, Navigation, A11y],
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    pagination: {
      el: this.$refs.pagination,
      type: 'bullets',
      clickable: true,
      bulletClass:
        'inline-block rounded-full w-1.5 h-1.5 ring-1 ring-gold-300/60 ring-offset-1 ring-offset-gold-300/60 cursor-pointer',
      bulletActiveClass: 'bg-gold-300/60',
    },
    navigation: {
      nextEl: this.$refs.next,
      prevEl: this.$refs.prev,
      disabledClass: 'opacity-50 cursor-not-allowed',
    },
    a11y: {
      enabled: true,
    },
  }

  return {
    swiper: undefined,

    init() {
      if (this.$refs.slider) {
        this.swiper = new Swiper(
          this.$refs.slider,
          merge(defaultConfig, config || {}),
        )
      }
    },

    goToSlide(index: number) {
      this.swiper?.slideTo(index)
    },

    discard() {
      this.swiper && this.swiper.destroy()
    },
  }
}
