import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import panelSlider from '@js/animations/panelSlider'
import splitBoxBanner from '@js/animations/splitBoxBanner'

gsap.registerPlugin(ScrollTrigger)

export const initGlobalAnimations = () => {
  let mm = gsap.matchMedia()

  gsap.config({
    nullTargetWarn: false,
  })

  mm.add('(prefers-reduced-motion: no-preference)', () => {
    // Hero animation, should always run first
    if (document.querySelector('.hero-img')) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: '.hero-img',
            start: 'top',
            end: 'bottom',
            scrub: true,
            refreshPriority: 0,
          },
        })
        .to('.hero-img img', {
          ease: 'power.in',
          scale: 1.1,
        })
        .to(
          '.hero-copy',
          {
            ease: 'power2.out',
            y: -100,
          },
          0,
        )
        .to(
          '.hero-line',
          {
            height: '100%',
          },
          0,
        )
    }

    // Pinned images
    const pinnedImgs: NodeListOf<HTMLElement> =
      document.querySelectorAll('[data-pinned-img]')
    if (pinnedImgs.length) {
      for (const item of pinnedImgs) {
        ScrollTrigger.create({
          trigger: item,
          pin: true,
          pinSpacing: false,
          start: 'top',
          end: () => 'bottom',
        })
      }
    }

    // Fade In
    gsap.utils.toArray('[data-fade-in]').forEach((element) => {
      const el: HTMLElement = element as HTMLElement
      gsap.fromTo(
        el,
        {
          opacity: 0,
          y: 30,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: el,
            start: 'top 80%', // Starts animation when element is 80% from the top of viewport
            toggleActions: 'play none none reverse',
          },
        },
      )
    })

    // Fade in children staggered
    gsap.utils.toArray('[data-fade-in-stagger]').forEach((element) => {
      const el: HTMLElement = element as HTMLElement
      let children = Array.from(el.children)

      if (el.dataset.fadeInStagger === 'reverse') {
        children = children.reverse()
      }

      gsap.from(children, {
        opacity: 0,
        y: 30,
        duration: 1,
        ease: 'power2.out',
        stagger: 0.1,
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      })
    })

    // Panel slider
    panelSlider()

    // Split Boxed Banner
    splitBoxBanner()

    // History Timeline Bar
    function historyTimeline() {
      const historyTimeline: HTMLElement | null =
        document.querySelector('.timeline')
      if (!historyTimeline) return

      const progressBar: HTMLElement | null =
        historyTimeline.querySelector('.timeline-progress')

      if (progressBar) {
        const ending = (progressBar.offsetHeight - window.innerHeight) * 0.45
        gsap.fromTo(
          progressBar,
          {
            scaleY: 0,
            transformOrigin: 'top center',
          },
          {
            scaleY: 1,
            ease: 'none',
            scrollTrigger: {
              trigger: progressBar,
              start: 'top 50%', // Starts when the element enters the viewport
              end: () => `bottom+=${ending} top`,
              scrub: true,
            },
          },
        )
      }

      const panels: Array<HTMLElement> = gsap.utils.toArray(
        historyTimeline.querySelectorAll('.timeline-block'),
      )

      panels.forEach((panel, index) => {
        const items = panel.querySelectorAll('h2,h3,.eyebrow,.prose,figure')

        const tl = gsap.timeline({
          default: {
            ease: 'none',
          },
          scrollTrigger: {
            trigger: panel,
            start: 'top 50%',
            // markers: true,
          },
        })

        tl.from(items, {
          opacity: 0,
          y: 100,
          duration: 0.3,
          ease: 'ease.out',
          stagger: 0.03,
        })
      })
    }
    historyTimeline()

    window.addEventListener('resize', () => {
      ScrollTrigger.refresh()
    })
  })
}
