import gsap from 'gsap'
import { getHeight } from '@js/animations/utils'

export default function panelSlider(): void {
  const panelSlider = document.querySelectorAll('[data-panel-slider]')
  if (panelSlider.length) {
    // If there's more than one slider, loop through them
    gsap.utils.toArray<HTMLElement>(panelSlider).forEach((panelWrapper) => {
      const panels: Array<HTMLElement> = gsap.utils.toArray(
        panelWrapper.querySelectorAll('[data-panel-card]'),
      )

      panelWrapper.classList.add('grid')

      // Create timeline for this section transition
      const tl = gsap.timeline({
        default: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: panelWrapper,
          start: 'top top',
          end: () => `bottom+=${getHeight(panelWrapper) * (panels.length + 1)}`,
          scrub: true,
          pin: true,
          // markers: true,
          invalidateOnRefresh: true,
        },
      })
      // gsap.set(panels, { height: '100svh' })

      panels.forEach((panel, index) => {
        // if (index === panels.length - 1) return // Skip last panel

        let image = panel.querySelector('[data-panel-card-img]')
        let bgImage = panel.querySelector('[data-card-bg]')
        let copy = panel.querySelector('[data-panel-card-copy]')

        panel.classList.add('col-start-1', 'row-start-1')

        if (image && copy) {
          // Add animations to timeline
          tl.to(
            panel,
            {
              autoAlpha: 1,
              // onComplete: function () {
              //   this.targets().forEach((elem: HTMLElement) =>
              //     elem.classList.remove('pointer-events-none'),
              //   )
              // },
            },
            `<`,
          )

          // Intro
          if (index !== 0) {
            tl.fromTo(
              bgImage,
              { autoAlpha: 0 },
              {
                autoAlpha: 1,
              },
              `<`,
            )
              .fromTo(
                image,
                { autoAlpha: 0, y: 50 },
                {
                  autoAlpha: 1,
                  y: 0,
                },
                `<`,
              )
              .fromTo(
                copy,
                { autoAlpha: 0, y: 50 },
                {
                  autoAlpha: 1,
                  y: 0,
                },
                `<`,
              )
          }

          // scroll content if needed
          const text = panel.querySelector('[data-panel-card-text]')
          if (text && text.clientHeight > copy.clientHeight) {
            const padding = window.getComputedStyle(copy)

            tl.to(text, {
              y: () =>
                -text.clientHeight +
                copy.clientHeight -
                parseInt(padding.getPropertyValue('padding-top')) * 2,
            })
          }

          // Outro
          if (index !== panels.length - 1) {
            tl.to(
              image,
              {
                autoAlpha: 0,
                y: -50,
              },
              `>`,
            )
              .to(
                copy,
                {
                  autoAlpha: 0,
                  y: -50,
                },
                `>`,
              )
              .to(panel, {
                autoAlpha: 0,
                // onComplete: function () {
                //   this.targets().forEach((elem: HTMLElement) =>
                //     elem.classList.add('pointer-events-none'),
                //   )
                // },
              })
          }
        }
      })
    })
  }
}
