// <div x-data x-marquee.speed.80.direction.1.pause.inertia>
//   <div>
//     ...children
//   </div>
// </div>

import { Marquee as MarqueeClass } from '@joycostudio/marquee'

interface Params {
  value: string
  modifiers: Array<string>
  expression: string
}

interface DirectiveParams {
  Alpine: typeof Alpine
  effect: (callback: () => void) => void
  cleanup: (callback: () => void) => void
}

export function Marquee(
  el: HTMLElement,
  { modifiers }: Params,
  { cleanup }: DirectiveParams,
) {
  const children = el.children

  if (children.length === 0) return
  if (children.length > 1) {
    console.error(
      'Marquee does not support multiple children. Wrap it up in a single element.',
    )
  }

  const child = children[0] as HTMLElement
  if (!child) return

  let hasSpeed = modifiers.includes('speed')
  let hasDirection = modifiers.includes('direction')
  let pauseOnhover = modifiers.includes('pause')
  let hasInertia = modifiers.includes('inertia')

  const speed = hasSpeed ? modifierValue(modifiers, 'speed', 40) : 10
  const direction = hasDirection ? modifierValue(modifiers, 'direction', 1) : 1

  const { root, wrapper } = createRootElement()

  const marq = new MarqueeClass(wrapper, {
    speed,
    speedFactor: 1,
    direction,
  })

  el.replaceChild(root, child)
  marq.initialize(child)

  if (hasInertia) addScrollInertia(marq, speed)

  if (pauseOnhover) {
    el.addEventListener('mouseenter', () => marq.pause())
    el.addEventListener('mouseleave', () => marq.play())
  }

  cleanup(() => {
    marq && marq.destroy()
  })
}

function createRootElement() {
  const root = document.createElement('div')
  root.style.overflowX = 'clip'
  root.style.overflowY = 'visible'
  root.style.maxWidth = '100%'

  const wrapper = document.createElement('div')
  wrapper.style.minWidth = 'max-content'
  wrapper.style.display = 'flex'

  root.appendChild(wrapper)

  return { root, wrapper }
}

function addScrollInertia(marquee: MarqueeClass, minSpeed: number) {
  let lastScrollY = window.scrollY
  let lastTimestamp = performance.now()
  let ticking = false
  let scrollTimeout: ReturnType<typeof setTimeout> | null = null

  function updateScrollVelocity() {
    const currentScrollY = window.scrollY
    const currentTimestamp = performance.now()

    const deltaY = currentScrollY - lastScrollY
    const deltaTime = (currentTimestamp - lastTimestamp) / 1000 // Convert ms to seconds
    const scrollDirection: 1 | -1 = deltaY > 0 ? 1 : -1
    const speed = Math.abs(deltaY / deltaTime) || 0 // Avoid division by zero

    // Normalize speed to a 1-10 range (adjust maxSpeed based on testing)
    const maxSpeed = 2000 // Tweak this to fit your use case
    const multiplier = Math.min(10, Math.max(1, (speed / maxSpeed) * 10))

    // speedfactor
    marquee.setSpeedFactor(multiplier * scrollDirection)

    lastScrollY = currentScrollY
    lastTimestamp = currentTimestamp
    ticking = false

    // Reset speedFactor after inactivity
    if (scrollTimeout) clearTimeout(scrollTimeout)
    scrollTimeout = setTimeout(() => {
      marquee.setSpeedFactor(1 * scrollDirection) // Reset to min speed
    }, 200) // Adjust delay as needed (200ms is a good starting point)
  }

  window.addEventListener('scroll', () => {
    if (!ticking) {
      ticking = true
      requestAnimationFrame(updateScrollVelocity)
    }
  })
}

function modifierValue(modifiers: Array<string>, key: string, fallback: any) {
  if (modifiers.indexOf(key) === -1) return fallback

  // If it IS present, grab the value after it: x-marquee.speed.40
  const rawValue = modifiers[modifiers.indexOf(key) + 1]

  if (!rawValue) return fallback

  if (key === 'speed' || key === 'direction') {
    if (isNaN(parseInt(rawValue))) return fallback
    return parseInt(rawValue)
  }

  return rawValue
}
