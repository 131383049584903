import gsap from 'gsap'

export default function splitBoxBanner(): void {
  const splitBoxedBanners = document.querySelectorAll(
    '[data-split-boxed-banner]',
  )

  if (splitBoxedBanners.length) {
    gsap.utils.toArray<HTMLElement>(splitBoxedBanners).forEach((section) => {
      const images = section.querySelectorAll('img')
      const article = section.querySelector('article')

      const tl = gsap.timeline({
        default: {
          ease: 'none',
        },
        scrollTrigger: {
          trigger: section,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
          pin: true,
          // markers: true,
          invalidateOnRefresh: true,
        },
      })

      tl.fromTo(
        images[0] as HTMLImageElement,
        {
          y: '10%',
        },
        {
          y: 0,
        },
        '<',
      )

      tl.fromTo(
        article,
        {
          y: '100%',
        },
        {
          y: '0',
        },
        '<',
      )
    })
  }
}
