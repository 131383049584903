import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import { Slider } from '@js/components/Slider.ts'
import { Marquee } from '@js/components/Marquee.ts'

import { initGlobalAnimations } from '@js/animations/animations.ts'
document.addEventListener('DOMContentLoaded', () => {
  initGlobalAnimations()
})

// Video facades
import '@js/components/liteYoutube.ts'
import '@js/components/liteVimeo.ts'

window.Alpine = Alpine

Alpine.plugin(intersect)
Alpine.plugin(focus)

Alpine.data('slider', Slider)
Alpine.directive('marquee', Marquee)

Alpine.start()
